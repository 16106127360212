$dark-factor: 5%;
$darker-factor: 8%;
$darkest-factor: 11%;
$text-factor: 25%;
$light-factor: 5%;
$lighter-factor: 8%;
$lightest-factor: 11%;

$transparent-factor: 0.08;

// START New COLORS White, black, grey
$white-color: #ffffff;
$grey-color-lightest: #f0f0f0;
$grey-color-lighter: #cdcdcd;
$grey-color-light: #afafaf;
$grey-color: #919191;
$grey-color-dark: #787878;
$grey-color-darker: #535353;
$grey-color-darkest: #434445;
$black-color: #272727;
// END

// START New COLORS PRIMARY
$primary-color: #0097b3;
$primary-color-dark: #00819a;
$primary-color-darker: #00687b;
$primary-color-light: rgba($primary-color, 0.15);
$primary-background-color: #cee2eb;
// END

// START New COLORS Success
$success-color: #27ae60;
$success-color-dark: #229955;
$success-color-darker: #1d8047;
// END

// START New COLORS Danger
$danger-color: #e7532b;
$danger-color-dark: #d13f18;
$danger-color-darker: #c43b16;
$danger-color-light: rgba($danger-color, 0.15);
// END

// START New COLORS Warning
$warning-color: #f7c244;
$warning-color-dark: #f6ba2c;
$warning-color-darker: #f5b00e;
// END

// START New COLORS Orange
$orange-color: #f6871b;
$orange-color-dark: #ee7a09;
$orange-color-darker: #df7309;
// END

$primary-color-matchy: #4941a0;
$primary-color-dark-matchy: darken($primary-color-matchy, $dark-factor);
$primary-color-darker-matchy: darken($primary-color-matchy, $darker-factor);
$primary-color-darkest-matchy: darken($primary-color-matchy, $darkest-factor);
$primary-color-light-matchy: lighten($primary-color-matchy, $light-factor);
$primary-color-lighter-matchy: lighten($primary-color-matchy, $lighter-factor);
$primary-color-lightest-matchy: lighten($primary-color-matchy, $lightest-factor);
$primary-color-transparent-matchy: rgba($primary-color-matchy, $transparent-factor);
$primary-background-color-matchy: #e6e1fa;

$secondary-color: #1ebba6;
$secondary-color-dark: darken($secondary-color, $dark-factor);
$secondary-color-darker: darken($secondary-color, $darker-factor);
$secondary-color-darkest: darken($secondary-color, $darkest-factor);
$secondary-color-light: lighten($secondary-color, $light-factor);
$secondary-color-lighter: lighten($secondary-color, $lighter-factor);
$secondary-color-lightest: lighten($secondary-color, $lightest-factor);
$secondary-color-transparent: rgba($secondary-color, $transparent-factor);

$badge-color: #cbecd8;
$badge-color-matchy: #cbeb04;

$default-filter-shadow: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.25));
