.redirect
  display: flex
  justify-content: center

  &_wrapper
    position: absolute
    top: 30%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

  &_link
    font-size: 16px
    font-weight: normal
    color: #ccc

  &_message
    font-size: 18px
    color: #838892
