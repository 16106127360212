@charset "utf-8"

@mixin font-face($name, $filename, $font-weight, $font-style: normal)
  font-family: $name
  src: url("/static/fonts/" + $filename + ".woff") format("woff")
  font-weight: $font-weight
  font-style: $font-style
  font-display: swap

@mixin linear-gradient-top($top, $bottom)
  background-color: $top
  background-image: -webkit-linear-gradient(top, $top, $bottom)
  background-image: -moz-linear-gradient(top, $top, $bottom)
  background-image: -ms-linear-gradient(top, $top, $bottom)
  background-image: linear-gradient(to bottom, $top, $bottom)

@mixin linear-gradient-left($left, $right)
  background-color: $left
  background-image: -webkit-linear-gradient(left, $left, $right)
  background-image: -moz-linear-gradient(left, $left, $right)
  background-image: -ms-linear-gradient(left, $left, $right)
  background-image: linear-gradient(to right, $left, $right)

@mixin transition($property, $duration)
  -webkit-transition: $property $duration
  -moz-transition: $property $duration
  -ms-transition: $property $duration
  transition: $property $duration

@mixin transform($value)
  -webkit-transform: $value
  -moz-transform: $value
  -ms-transform: $value
  transform: $value

@mixin user-select($value)
  -webkit-user-select: $value
  -moz-user-select: $value
  user-select: $value

@keyframes shake
  0%, 100%
    transform: translate3d(0, 0, 0)

  10%
    transform: translate3d(-10px, 0, 0)
  30%
    transform: translate3d(-7px, 0, 0)
  50%
    transform: translate3d(-4px, 0, 0)
  70%
    transform: translate3d(-2px, 0, 0)
  90%
    transform: translate3d(-1px, 0, 0)

  20%
    transform: translate3d(10px, 0, 0)
  40%
    transform: translate3d(6px, 0, 0)
  60%
    transform: translate3d(3px, 0, 0)
  80%
    transform: translate3d(1px, 0, 0)

=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content

@mixin animation($value)
  -webkit-animation: $value
  -moz-animation: $value
  -ms-animation: $value
  animation: $value

@mixin placeholder
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content

@font-face
  @include font-face("Proxima Nova", ProximaNova-Black, 900)

@font-face
  @include font-face("Proxima Nova", ProximaNova-Extrabld, 800)

@font-face
  @include font-face("Proxima Nova", ProximaNova-Bold-webfont, bold)

@font-face
  @include font-face("Proxima Nova", ProximaNova-Semibold, 600)

@font-face
  @include font-face("Proxima Nova", ProximaNova-Reg-webfont, normal)

@font-face
  @include font-face("Proxima Nova", ProximaNova-Light, 300)

@font-face
  @include font-face("Proxima Nova", ProximaNova-Thin, 100)

@font-face
  @include font-face("Proxima Nova", ProximaNova-BlackIt, 900, italic)

@font-face
  @include font-face("Proxima Nova", ProximaNova-ExtrabldIt, 800, italic)

@font-face
  @include font-face("Proxima Nova", ProximaNova-BoldIt, 700, italic)

@font-face
  @include font-face("Proxima Nova", ProximaNova-SemiboldIt, 600, italic)

@font-face
  @include font-face("Proxima Nova", ProximaNova-RegularIt, 400, italic)

@font-face
  @include font-face("Proxima Nova", ProximaNova-LightIt, 300, italic)

@font-face
  @include font-face("Proxima Nova", ProximaNova-ThinIt, 100, italic)

$color-a-gray: #8a8d91
$color-a-gray-hover: #707378
$color-a-gray-active: #8a8d91

$color-border: #ced3d6
$color-button: #333a46

$color-notification-bg: #eaeaf4
$color-notification-highlight: #f5f5fa

$color-base-red: #dc9184
$color-base-green: #acd376
$color-base-blue: #14bad7

$background-body: #e4e5e7
$background-badge: #10b9d6
$background-gray-icon: #919598

$font-ubuntu: "Ubuntu Mono", Arial, sans-serif
$font-proxima: "Proxima Nova", sans-serif
$font-default: "Proxima Nova", sans-serif

$width-app: 1000px
