@charset "utf-8"

@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
@import "../../node_modules/rc-calendar/assets/index.css"
@import "../../node_modules/@fortawesome/fontawesome-svg-core/styles.css"

@import "modules/ui"
@import "modules/redirect"
@import 'components/Base/Colors/Colors'

:root
  accent-color: $primary-color
