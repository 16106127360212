@charset "utf-8"
@import "base"

html, body, h1, h2, h3, h4, h5, h6, p, form, ul, ol, li, label
  padding: 0
  margin: 0


html, body
  height: 100%
  min-width: 640px

body
  background: $background-body

body,
input,
textarea,
button,
h1,
h2,
h3,
h4,
h5,
h6
  font-family: $font-default
  color: var(--grey-color-darkest)

ul, ol
  list-style-type: none

a
  color: var(--primary-color)
  text-decoration: none
  cursor: pointer
  transition: color 0.2s ease-in-out
  &:hover
    color: var(--primary-color-dark)
    text-decoration: none
  &:active
    color: var(--primary-color-darker)
    text-decoration: none
  &:focus
    text-decoration: none

label
  font-weight: normal

.b-app
  background: #fbfbfb
  font-size: 14px
  line-height: 18px

.m-hidden
  display: none !important

#sprite
  position: absolute
  width: 0
  height: 0
